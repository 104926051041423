.phoneInput {
  font-family: "Poppins-regular";
  font-size: 16px !important;
  color: var(--text-color-black);
  letter-spacing: 1.4px !important;
  background-color: #fff !important;
  width: 100% !important;
  min-height: 57px !important;
  border: none !important;
}

.phoneInputContainer {
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
}

.phoneInput:focus {
  box-shadow: none !important;
}

.phoneInput::placeholder {
  color: var(--placeholder-color);
  font-family: "Poppins-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.phoneLabel {
  text-transform: capitalize;
  color: var(--text-color-black);
  font-family: "Poppins-medium";
  font-size: 16px;
  margin-bottom: 8px;
}

.labelDisabled {
  color: #ababab;
}
