.main {
  padding: 10px 30px;
  /* max-width: 77%; */

  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: -4px 4px 20px #00000029;
  border-radius: 10px;
  cursor: pointer;
}
.headWrap {
  display: flex;
  justify-content: space-between;
}
.delivery {
  color: #a8a8a8;
  font-size: 18px;
}
.desc {
  font-size: 20px;
}
.schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: var(--primary-color);
  color: #ffff;
  margin-inline-end: 20px;
  text-transform: capitalize;
}
.rideDetailsWrap {
  display: flex;
  justify-content: space-between;
}
.rideDetails {
  display: flex;
  gap: 20px;
}
.locationDetailWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.locationHead {
  font-size: 13px;
}
.location {
  color: var(--primary-color);
  font-size: 20px;
}

.time {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #989898;
}

.icon1Wrap {
  display: flex;
  align-items: start;
  position: relative;
}
.icon1Wrap::after {
  position: absolute;
  content: "";
  border-style: dashed;
  border-color: var(--primary-color);
  border-width: 1px;
  top: 24px;
  left: 11px;
  height: calc(100% - 9px);
}
.icon2Wrap {
  display: flex;
  align-items: end;
  position: relative;
}
.icon2Wrap::before {
  position: absolute;
  content: "";
  border-style: dashed;
  border-color: var(--primary-color);
  border-width: 1px;
  bottom: 26px;
  left: 11px;
  height: calc(100% - 8px);
}

@media (max-width: 767px) {
  .main {
    padding: 10px 20px;
  }
  .icon1Wrap::after {
    /* height: 60px; */
  }
  .schedule {
    padding: 1px 5px !important;
    margin-inline-end: 0px;
    font-size: 13px;
  }
  .delivery {
    font-size: 15px;
  }
  .location {
    font-size: 17px;
  }
  .desc {
    font-size: 14px;
  }
  .time {
    font-size: 17px;
  }
}
@media (max-width: 450px) {
  .main {
    padding: 10px;
  }
  .schedule {
    padding: 1px 3px !important;
    margin-inline-end: 0px;
    font-size: 10px;
  }
  .location {
    font-size: 17px;
  }
  .desc {
    font-size: 14px;
  }
  .time {
    font-size: 14px;
  }
}
@media (max-width: 370px) {
  .main {
    padding: 10px;
  }
  .schedule {
    padding: 0px 11px !important;
    margin-inline-end: 0px;
    height: 22px;
    font-size: 10px;
  }
  .delivery {
    font-size: 12px;
  }
  .location {
    font-size: 17px;
  }
  .desc {
    font-size: 14px;
  }
  .time {
    font-size: 12px;
  }
}
