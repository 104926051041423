.mainContainer {
  height: 100vh;
  width: 100%;
  /* background: var(--dashboard-main-color); */
  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
  overflow-y: scroll;
  padding-right: 30px;

}

.logoContainer {
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
}

.logoContainer img {
  width: 120px;
  cursor: pointer;
  height: auto;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 10px;
  padding-left: 20px;
  margin-block: 8px;
  cursor: pointer;
  position: relative;
  user-select: none;

}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-family: "Poppins-medium";
  font-size: 16px;
  color: var(--sidebar-text-color);
  margin-left: 18px;
}

.listItemContainer svg,
.innerItemContainer svg {
  color: var(--primary-color);
}

::-webkit-scrollbar {
  display: none;
}


.innerItemContainer {
  padding-block: 10px;
  padding-left: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerItemContainer span {
  font-family: "Poppins-medium" !important;
  font-size: 14px;
  color: var(--sidebar-text-color);
  margin-left: 18px;
}

.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
  fill: var(--primary-color);
}


.subActive span {
  color: #ffffff;
  /* text-decoration: underline; */
}

.subActive svg {
  color: #ffffff !important;
}

.subActive .dropDownIcon {
  fill: #ffffff;
}

.subActive {
  margin-bottom: 10px !important;
}

.active,
.subActive {
  background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box !important;
  border-top-right-radius: 46px;
  border-bottom-right-radius: 46px;
}

.active span {
  color: #ffffff;
}

.active svg {
  color: #ffffff !important;
}

.btn {
  padding: 15px 60px !important;
  margin: 7px 10px !important;
}