.header {
  padding: 15px 0px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-block: 10px;
  padding-inline: 45px;
  max-width: 100% !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 16px 11px 30px #0000001f;
}

.rightSide {
  display: flex;
  align-items: center;
}

.leftSide {
  flex-grow: 1;
  max-width: 65%;
}

.logo {
  width: 100px;
  height: 50px;
}

.wrapper {
  font-family: "Inter", sans-serif;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

svg {
  display: inline-block;
  vertical-align: top;
}

.mainColor {
  color: #4d55e5;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.linkContainer span {
  margin-right: 10px;
}

.logoImage {
  width: 156px;
  height: 36px;
  vertical-align: middle;
}

.goBackLink {
  padding-left: 30px;
}

.nabarLinks {
  color: var(--text-color-black) !important;
  font-family: "plus-jakarta-display-regular";
  font-size: 18px !important;
  padding: 8px 0px;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 600;
}

.nabarLinks:hover {
  transition: all 0.4s ease-in-out;
  color: var(--main-color) !important;
}

.navActive {
  color: var(--main-color) !important;
  border-bottom: 2px solid var(--main-color);
}

.signin_button {
  background-color: var(--button-color);
  border: 1px solid var(--main-color);
  color: var(--text-color-black);
  margin-right: 14px;
}

.btn {
  padding: 10px 30px !important;
  border-radius: 30px;
  font-family: "plus-jakarta-display-regular";
}

.signup_button {
  background-color: var(--main-color);
  color: var(--white-color);
  border: 1px solid var(--main-color);
}

.gapCustm {
  gap: 3rem;
}

.dflex {
  display: flex;
}

.signup_button {
  color: var(--white-color) !important;
}

.profileImg {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  /* border: 2px solid #929eaa; */
  background-color: #d6d6d6;

  box-shadow: 7px 7px 22px #00000017;
  user-select: none;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
  cursor: pointer;
}

.iconDiv {
  display: flex;
  align-items: center;
  margin-right: 12px;
  user-select: none;
  cursor: pointer;
}

.notifyoverlayDiv {
  top: 10px !important;
  left: -90px !important;
  z-index: 2;
}

.SettingsoverlayDiv {
  top: 12px !important;
  left: -10px !important;
  z-index: 2;
}

.SettingsPopup {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #0000004a;
  padding: 12px 30px;
  width: max-content;
}

.editProfileIcons {
  vertical-align: bottom !important;
  margin-right: 5px;
}

.editprofileDiv {
  margin-bottom: 10px;
  color: var(--text-dark-gray);
  cursor: pointer;
}

.notiIcon {
  width: 42px;
  height: 42px;
  /* UI Properties */
  background: transparent
    linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    0% 0% no-repeat padding-box !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notiCount {
  position: absolute;
  top: -8px;
  right: -2px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.userWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.userWrap p {
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 1600px) {
  .leftSide {
    max-width: 75%;
  }
}

@media (max-width: 1200px) {
  .gapCustm {
    gap: 1.5rem;
  }
}

@media (max-width: 1080px) {
  .gapCustm {
    gap: 1rem;
  }

  .nabarLinks {
    font-size: 16px !important;
  }

  .iconDiv svg {
    width: 25px !important;
    height: 25px !important;
  }

  .profileImg {
    width: 50px;
    height: 50px;
  }
}
