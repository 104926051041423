.main {
  padding-block: 20px;
  max-width: 96% !important;
  height: calc(100vh - 85px);
  overflow: scroll;
}
.routes_box {
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 20px;
  box-shadow: -4px 4px 30px #0000001f;
}
.account_head > button {
  color: #fff;
}
.account_pic_wrapper {
  width: 250px;
  height: 250px;
  margin-bottom: 40px;
}
.account_pic_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.account_details {
  width: 750px;
}
.account_desc {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}
.account_desc p {
  font-size: 25px;
  width: 45%;
}
.account_desc b {
  font-size: 30px;
  font-family: "Poppins-semiBold";
}
.account_head {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.rideCardWrap > h3 {
  color: var(--primary-color);
  font-family: "Poppins-semiBold";
  margin-bottom: 30px;
}

.small_cricles .circle h5 {
  margin: 0;
  font-size: 18px;
}
.small_cricles p {
  font-size: 13px;
}
.small_cricles .circle {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--primary-color);
  margin-bottom: 5px;
}

.box_main div:nth-child(2) {
  font-weight: bolder;
}
.small_cricles div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.small_cricles {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.box_main {
  height: 300px;
  box-shadow: 0px 0px 11px #d2cece;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circle h5 {
  margin: 0;
  font-size: 38px;
}
.circle {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--primary-color);
  margin-bottom: 20px;
}
.box_main :global(.row) {
  height: 100%;
  width: 100%;
}
.left_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader{
  height: calc(100vh - 85px);
}