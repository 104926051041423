.labelText {
  color: var(--text-color-black);
  text-transform: capitalize;
  font-family: "Poppins-medium";
  font-size: 16px;
  margin-bottom: 8px;
}

.inputDiv {
  position: relative;
  display: flex;
  background-color: var(--white-color);
  box-shadow: rgba(0, 0, 0, 0.07) 21px 14px 24px;
  border-radius: 10px;
  /* border: 1px solid rgb(229, 229, 229); */
}

.comboboxInput {
  font-family: "Poppins-regular";
  font-size: 16px;
  color: var(--text-color-black);
  letter-spacing: 1.4px;
  border-radius: inherit;
  background-color: inherit;
  padding: 15px;
  width: 100%;
}

.inputDiv input {
  background-color: inherit;
  box-shadow: rgba(0, 0, 0, 0.12) -4px 4px 30px;
}

.leftIcon {
  position: absolute;
  left: 20px;
  /* top: 55%; */
  top: 60%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

.inputDiv input::placeholder {
  color: var(--placeholder-color);
  font-family: "Poppins-regular";
  font-size: 16px;
  text-transform: capitalize;
}

.comboBoxList {
  position: absolute;
  background: #f4f4f4;
  top: 100%;
  z-index: 9999;
  /* border: 1px solid #000; */
  width: 100%;
}
