.btn {
  outline: none;
  border: none;
  font-family: "Poppins-regular";
  padding: 15px 20px;
  line-height: 1;
  font-size: 16px;
  border-radius: 5px;
  background: var(--primary-color);

}

.btn[color-variant='yellow'] {
  color: var(--white-color);
}

.btn[color-variant='white'] {
  background-color: var(--white-color);
  color: var(--btn-blue-color);
}