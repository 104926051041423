.main {
    padding-block: 20px;
    max-width: 96% !important;
    /* height: 90%; */
}

.notificationContainer {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 265px);
    gap: 10px;
}


.content {
    overflow-y: scroll;
}

.loader {
    min-height: calc(100vh - 255px)
}

.row {
    row-gap: 20px;
}

.row h6 {
    font-family: 'Poppins-semiBold';
    font-size: 30px;
}

.btns {
    display: flex;
    gap: 10px;
}

.viewDetailBtn {
    box-shadow: 21px 14px 24px #654ACB30;
    border-radius: 46px;
    padding-block: 14px;
    width: 150px;
}

.uploadBtn {
    box-shadow: 21px 14px 24px #654ACB30;
    padding-block: 14px;
}

.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.spBtw h3{
    color: var(--primary-color);
}
.paginationDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.actions_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.searchAndFilters{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
/* Notification Box */
.createBtn{
    padding-block: 17px;
}



.content :global(.table100-body){
    height: calc(100vh - 367px);
}






@media screen and (max-width:767px) {
    .ImgDiv {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        font-size: 35px;
    }

}

@media screen and (max-width:575px) {
    .HeaderNotificationInnerDiv .titleDiv p {
        font-size: 14px;
        width: 100%;
        text-align: right;
    }

    .ImgDiv {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        font-size: 30px;
    }

}

@media screen and (max-width:375px) {
    .HeaderNotificationInnerDiv .titleDiv p {
        font-size: 13px;
    }

    .ImgDiv {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        font-size: 25px;
    }

}