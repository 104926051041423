.labelText {
    color: var(--text-color) !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    margin-bottom: 2px !important;
}

.loginBtn {
    width: 100%;
    color: white;
    margin-top: 20px;
}

.jCenter {
    display: flex;
    justify-content: center;
}