.HeaderNotification {
  width: 450px;
  height: 400px;
  background-color: white;
  box-shadow: 0 0 4px 0px #0000004a;
  /* padding: 0px 0px 16px 0px; */
  border-radius: 12px;
  overflow-y: scroll;
}

.ImgDiv {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c84a;
}

.HeaderNotificationInnerDiv {
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.5s;
}

.HeaderNotificationInnerDiv:hover {
  background: #ffa7a74a;
}

.contentDiv {
  margin-left: 12px;
  width: 100%;
}

.contentDiv h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 6px;
  font-size: 18px;
}

.contentDiv p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color-gray);
  margin-bottom: 0px;
  font-size: 16px;
}

.mb16 {
  /* margin-bottom: 16px; */
}

.viewAll {
  text-align: center;
  color: var(--text-black-color);
  cursor: pointer;
  margin-bottom: 0px;
  font-family: 'Poppins-semiBold';
  border-top: 1px solid rgb(233, 199, 199);
  padding-block: 10px;
  position: sticky;
  bottom: 0;
  background-color: var(--white-color);
  transition: all 0.5s;
}

.viewAll:hover {
  background-color: rgb(236, 236, 236);
}

.notificationHeader {
  margin-bottom: 10px;
  padding-left: 16px;
  background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
  color: var(--white-color);
  padding-block: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.notificationHeader h6 {
  margin-bottom: 0px;
}

.titleDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.notificationSkeleton {
  gap: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.noData {
  height: unset !important;
  min-height: 100% !important;
}

.titleDiv p {
  width: 100%;
  text-align: right;
  font-size: 14px;
}