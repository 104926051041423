.labelText {
  color: var(--text-color) !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  margin-bottom: 2px !important;
}
.row_main {
  row-gap: 20px;
}

.loginBtn {
  float: center;
  width: 100%;
  margin-top: 20px;
  color: white;
}

.jCenter {
  display: flex;
  justify-content: center;
}

.map {
  /* height: 300px; */
}

.phoneInput {
}
