.labelText {
    color: var(--text-color) !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    margin-bottom: 2px !important;
}

.loginBtn {
    width: 100%;
    color: white;
}

.jCenter {
    background-color: red;
    display: flex;
    justify-content: center;
}

.listContainer ul {
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

}

.listContainer ul li {
    box-shadow: 1px 1px 15px 4px #59585821;
    border-radius: 46px;
    padding: 5px 10px;
    border: 1px solid #E5E5E5;
    justify-content: space-between;
    display: flex;
    align-items: center
}

.labelDiv {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.labelDiv span:last-child {
    font-size: 12px;
}

.row {
    row-gap: 10px
}