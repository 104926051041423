.labelAndValue{
display: flex;
flex-direction: column;
row-gap: 8px;
}
.labelAndValue p{
    margin-bottom: 0px;
    font-size: 15px;
}
.labelAndValue label{
    font-family: 'Poppins-semiBold';
    font-size: 17px;
}