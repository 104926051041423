/* Boiler plate start */

.main {
  padding-block: 20px;
  max-width: 96% !important;
  height: calc(100vh - 85px);
  overflow: scroll;
}

.rideCardWrap > h3 {
  color: var(--primary-color);
  font-family: "Poppins-semiBold";
  margin-bottom: 30px;
}

.account_head {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.account_head > button {
  color: #fff;
}

/* Boiler plate end */

.gap {
  row-gap: 20px;
}
.label {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.label p {
  margin: 0px !important;
  font-size: 22px;
}

.locationAutoComplete > div {
  box-shadow: 0px 0px 6px #00000029;
}

.locationAutoComplete input {
  align-items: center;
  background-color: #ffffff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-width: 1px;
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 54px;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.routeDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.routeDetail h6 {
  color: var(--primary-color);
}

.btn {
  background-color: var(--primary-color) !important;
  padding: 15px;
  color: #ffff !important;
}

.routeNo {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.routeNo p {
  margin: 0px !important;
  font-weight: bold;
  font-size: 20px;
}

.routeNo svg {
  cursor: pointer;
}

.singleRouteContainer {
  box-shadow: 0px 0px 6px #00000029;
  padding: 20px;
  margin: 10px 0 30px 0;
  border-radius: 5px;
}

.confirmBtn {
  margin-top: 30px;
}
