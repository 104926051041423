.main {
  padding-block: 20px;
  max-width: 96% !important;
  height: calc(100vh - 85px);
  overflow: scroll;
}

.rideCardWrap > h3 {
  color: var(--primary-color);
  font-family: "Poppins-semiBold";
  margin-bottom: 30px;
}

.account_head {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.map {
  height: 500px;
}

.rideMapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.rideMapperItem {
  display: flex;
  align-items: center;
  position: relative;
}

.rideMapperItem svg {
  margin-right: 10px;
  color: var(--primary-color);
  font-size: 24px;
}

.rideMapperItem span {
  font-size: 20px;
}
