.labelText {
  color: var(--text-color) !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  margin-bottom: 2px !important;
}

.loginBtn {
  float: center;
  width: 100%;
  margin-top: 20px;
  color: white;
}

.jCenter {
  display: flex;
  justify-content: center;
}

.row_main {
  row-gap: 20px;
}
.modalBody{
    height: 700px;
    overflow-y:auto ;
}