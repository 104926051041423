.main {
    padding-block: 20px;
    max-width: 96% !important;
    /* height: 90%; */
}

.notificationContainer {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 265px);
    gap: 10px;
}


.content {
}

.loader {
    min-height: calc(100vh - 255px)
}

.row {
    row-gap: 20px;
}

.row h6 {
    font-family: 'Poppins-semiBold';
    font-size: 30px;
}

.btns {
    display: flex;
    gap: 10px;
}

.viewDetailBtn {
    /* background: transparent linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%) 0% 0% no-repeat padding-box; */
    box-shadow: 21px 14px 24px #5f40ce30;
    padding-block: 10px;
}

.uploadBtn {
    background: transparent linear-gradient(90deg, #E65D90 0%, #654ACB 100%) 0% 0% no-repeat padding-box;
    box-shadow: 21px 14px 24px #654ACB30;
    padding-block: 14px;
}

.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paginationDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.actions_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.spBtw h3{
    color: var(--primary-color);
}

/* Notification Box */



.content :global(.table100-body){
    height: calc(100vh - 367px);
}

